.singen__main {
  grid-area: main;
  background: #ffffff;
  padding: var(--distance);
  margin: 0 0 var(--distance);
  min-height: calc(100vh - 130px);
}
.singen__flexrow {
  display:flex;
}
.singen__flexrow__item_1_3 {
  flex: 1 1 33%;
}
.singen__flexrow__item_2_3 {
  flex: 1 1 66%;
}

.singen__booklink {
  display: block;
  margin: 0;
  padding: 0;
  width: 32%;
  }

.singen__booklist {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
