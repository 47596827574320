.singen__header {
  background: #ffffff;

  @media screen and (min-width: 900px) {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 90px;
    z-index: 100;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 calc(var(--distance) * -1) var(--distance);
  padding: calc(var(--distance) / 2) var(--distance);

  h1 {
    margin: 0;
    flex: 1;
    line-height: 1;
    font-size: 3.2rem;
    //font-weight: 400;
  }

  border-bottom: 1px solid var(--accent);
}

.singen__header__logo__img {
  width: 70px;
  margin: 0 var(--distance) 0 0;
}

.singen__header__logo__img {
  width: 90px;
  margin: 0 var(--distance) 0 0;
}
