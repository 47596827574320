:root {
  --primary: #fffffff;
  --accent: #495bba;
  --accent-font: #ffffff;
  --font: #333333;
  --font-accent: #007580;
  --font-important: red;
  --link-color: #e70554;
  --link-hover-color: purple;

  --distance: 0.8rem;

  @media screen and (min-width: 900px) {
    --distance: 1.6rem;
  }
}
