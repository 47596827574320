.singen__footer {
  grid-area: footer;
  margin: 0 calc(var(--distance) * -1) 0;
  padding: var(--distance) 0;

  display: flex;
  justify-content: space-around;

  background: var(--accent);
  color: var(--accent-font);

  a {
    color: var(--accent-font);

    &:hover {
      color: var(--font);
    }
  }

}
