.singen__resp_iframe {
  position: relative;
  width: 100%;
  padding-top: 66%;
  margin: 0 0 calc(var(--distance) * 1.5);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
