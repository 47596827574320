.singen__main_nav {
  grid-area: menu;
  background: #ffffff;
  margin: 0 0 var(--distance) 0;
  padding: var(--distance);


}

.singen__persons  {

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-inline-start: 0;
  }
  li {
    display: inline-block;
    padding: 0;
  }


  a {
    display: block;
    margin: calc(var(--distance) / 4);
    padding: calc(var(--distance) / 4) calc(var(--distance) / 2);
    border-radius: 0.5rem;
    background: var(--accent);
    color: var(--accent-font);
    font-size: 1rem;
  }

}
