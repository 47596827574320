@import "reset";
@import "root";

html {
  // Best practice is to create a font size percentage that defines 1 rem as 10 px so that you can calculate values easily. 1.6 rem = 16px;
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px 10px/16px = 62.5%
  margin: 0;
  padding: 0;

  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 1.6rem;
  color: var(--font);
  font-weight: 400;

  line-height: 1.4;

  box-sizing: border-box;
  margin: 0;
  padding: 0 var(--distance);
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;

  background-color: #ffffff;
  background-image: url('/images/PIXNIO-2369909-6000x4000.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 900px) {
    padding: 110px 0 0;

    display: grid;
    grid-template-columns: 0 30rem auto 0;
    grid-template-rows: auto;
    grid-column-gap: var(--distance);

    grid-template-areas: ". menu main ." ". aside main ." ". aside main ." "footer footer footer footer"
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 15% 30rem auto 15%;
  }
}

strong {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: var(--link-color);

  &:hover {
    color: var(--link-hover-color);
  }
}

.singen__video {
//  padding: var(--distance) 0;
  margin: var(--distance) 0;
  border-top: 1px solid lightgrey;
  //border-bottom: 1px solid var(--accent);
}

@import "header";
@import "heading";
@import "main-nav";
@import "main";
@import "iframe";
@import "aside";
@import "footer";

.singen__citeAndAuthor {
  background-color: #eeeeee;
  padding: 2rem;
  margin: 2rem;
  font-weight: bold;
}


.singen__newestvideo {
  margin-top:5rem;
}

#singen__introduction {
  margin-top:2rem;
}
