*,
*:after,
*:before {
  box-sizing: inherit;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}
