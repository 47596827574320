h1, h2, h3 {
  font-family: 'Dancing Script', cursive;
  margin: 0 0 1.6rem;
  font-weight: 500;
}

h1 {
  font-size: 4rem;
  color: var(--font);
}

h2 {
  font-size: 2.8rem;
  color: var(--font-accent);
}

h3 {
  font-size: 2.4rem;
  color: var(--font);
}
